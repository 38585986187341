import { connect } from 'react-redux'

import Height from './height'

import { nextStep, submitData } from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  submitData,
})(Height)
