import { connect } from 'react-redux'

import Household from './household'

import { nextStep, submitData } from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  submitData,
})(Household)
