import React, { Component } from 'react'
// import { navigate } from "gatsby"

const Months = [
  { value: '1', name: 'January', days: 31 },
  { value: '2', name: 'February', days: 28 },
  { value: '3', name: 'March', days: 31 },
  { value: '4', name: 'April', days: 30 },
  { value: '5', name: 'May', days: 31 },
  { value: '6', name: 'June', days: 30 },
  { value: '7', name: 'July', days: 31 },
  { value: '8', name: 'August', days: 31 },
  { value: '9', name: 'September', days: 30 },
  { value: '10', name: 'October', days: 31 },
  { value: '11', name: 'November', days: 30 },
  { value: '12', name: 'December', days: 31 },
]

class Dob extends Component {
  constructor(props) {
    super(props)
    this.state = {
      month: 0,
      day: 0,
      year: '',
      section: 'months',
      disabled: 'disabled',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.selectMonth = this.selectMonth.bind(this)
    this.selectDay = this.selectDay.bind(this)
  }
  componentDidMount = () => {
    let formKey
    for (formKey in this.state) {
      if (this.props.captive.data[formKey] && this.props.captive.data[formKey] !== '') {
        let val = this.props.captive.data[formKey]
        if (formKey === 'year' && val !== '') {
          val = val.toString().slice(-2)
          this.setState({
            disabled: '',
          })
        }
        this.setState({
          [formKey]: val,
        })
      }
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.submitDob(this.state.year)
    this.props.handleNext(this.props.captive.step)
  }
  selectMonth = (monthValue) => {
    //this.setState({month: monthValue, section: 'days'});
    monthValue = monthValue * 1
    this.props.submitData({ month: monthValue })
    this.props.handleNext(this.props.captive.step)
  }
  selectDay = (dayValue) => {
    //this.setState({day: dayValue, section: 'year'});
    this.props.submitData({ day: dayValue })
    this.props.handleNext(this.props.captive.step)
  }
  handleChange(event) {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 2 ? '' : 'disabled'
    const intValue = event.target.value

    if (!isNaN(intValue) && intValue !== ' ') {
      this.setState({ year: intValue, disabled: isDisabled })
    }
  }
  determineButtonClass = (classes, id, val) => {
    return classes + (this.state[id] == val ? ' picked' : '')
  }
  determineTitle = () => {
    switch (this.props.captive.step) {
      case 'dobyear':
        return 'What is your birth year?'
      case 'dobday':
        return 'What day of the month were you born?'
      case 'dobmonth':
      default:
        return 'What month of the year were you born?'
    }
  }
  determineForm = () => {
    switch (this.props.captive.step) {
      case 'dobyear':
        return this.renderYear()
      case 'dobday':
        return this.renderDays()
      case 'dobmonth':
      default:
        return this.renderMonths()
    }
  }

  renderMonths = () => {
    return (
      <div className="months">
        {Months.map(({ value, name }) => (
          <span
            key={value}
            className={this.determineButtonClass(
              'month btn btn-secondary',
              'month',
              value,
            )}
            onClick={() => this.selectMonth(value)}
          >
            {name}
          </span>
        ))}
      </div>
    )
  }
  renderDays = () => {
    let dayCount = 31
    for (let d = 0; d < Object.keys(Months).length; d++) {
      if (Months[d].value === this.state.month) {
        dayCount = Months[d].days
      }
    }
    let items = []
    for (let i = 1; i <= dayCount; i++) {
      items.push(
        <span
          key={i}
          className={this.determineButtonClass('day btn btn-secondary', 'day', i)}
          onClick={() => this.selectDay(i)}
        >
          {i}
        </span>,
      )
    }
    return <div className="days">{items}</div>
  }
  renderYear = () => {
    return (
      <div className="year">
        <h3>19</h3>
        <input
          type="text"
          value={this.state.year}
          data-required="true"
          onChange={this.handleChange}
          maxLength="2"
        />
        <div className="btn-wrap text-center">
          <button
            disabled={this.state.disabled}
            type="submit"
            className={`btn` + (this.state.disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
      </div>
    )
  }

  render = () => (
    <div>
      <strong className="h4">{this.determineTitle()}</strong>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation dob"
        noValidate="novalidate"
      >
        {this.determineForm()}
      </form>
    </div>
  )
}

export default Dob
