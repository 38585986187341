import React, { Component } from 'react'

class Household extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = (value) => {
    this.props.submitData({ household: value })
    this.props.handleNext('household')
  }

  render = () => {
    let items = []
    for (let i = 1; i <= 8; i++) {
      items.push(
        <span
          key={i}
          className="household btn btn-secondary"
          onClick={() => this.handleSubmit(i)}
        >
          {i}
        </span>,
      )
    }
    return (
      <div>
        <strong className="h4">How many people reside in your household?</strong>
        <div className="households">{items}</div>
      </div>
    )
  }
}

export default Household
