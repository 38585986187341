import React, { Component } from 'react'
import Slider from '@material-ui/lab/Slider'

import './income.scss'

class Income extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: 65,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  handleSubmit = () => {
    let val = this.state.slider * 1000
    val = val.toString()
    this.props.submitData({ income: val })
    this.props.handleNext('income')
  }
  handleChange = (e, val) => {
    this.setState({ slider: val + 1 })
  }

  render = () => {
    const { slider } = this.state
    const dir = slider === 21 ? 'less' : 'more'
    const classes = {
      container: 'income-container',
      track: 'income-track',
      trackBefore: 'income-track-before',
      trackAfter: 'income-track-after',
      thumb: 'income-thumb',
    }

    return (
      <div id="incomes">
        <strong className="h4">
          What is your estimated
          <br /> household income?*
        </strong>
        <p className="h6">
          <em>*Income level does not affect your rate.</em>
        </p>
        <p className="h4 blue">
          ${slider},000 or {dir}
        </p>
        <div className="income-headings">
          <span>$21K</span>
          <span>$45K</span>
          <span>$69K</span>
        </div>
        <div className="income-bar">
          <Slider
            step={4}
            classes={classes}
            value={slider}
            min={21}
            max={69}
            name="income"
            onChange={this.handleChange}
          />
        </div>
        <button className="btn" onClick={this.handleSubmit}>
          Continue
        </button>
      </div>
    )
  }
}

export default Income
