import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import './height.scss'

class Height extends Component {
  constructor(props) {
    super(props)
    this.state = { ft: '', in: '', disabled: 'disabled' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.submitData({ ft: this.state.ft, in: this.state.in })
    this.props.handleNext('height')
  }
  handleChange = (event) => {
    const id = event.target.id
    const type = event.target.type
    const val = type === 'checkbox' ? event.target.checked : event.target.value

    this.setState({ [id]: val }, () => {
      this.checkDisabled()
    })
  }
  checkDisabled = () => {
    let disabled = this.state.ft === '' || this.state.in === '' ? 'disabled' : ''
    this.setState({ disabled: disabled })
  }

  render = () => (
    <div id="height">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">How tall are you?</strong>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col half-width">
            <label htmlFor="ft">Feet</label>
            <select id="ft" onChange={this.handleChange} data-required="true">
              <option value="">ft.</option>
              <option value="3">3'</option>
              <option value="4">4'</option>
              <option value="5">5'</option>
              <option value="6">6'</option>
              <option value="7">7'</option>
            </select>
          </div>
          <div className="col half-width">
            <label htmlFor="in">Inches</label>
            <select id="in" onChange={this.handleChange} data-required="true">
              <option value="">in.</option>
              <option value="0">0"</option>
              <option value="1">1"</option>
              <option value="2">2"</option>
              <option value="3">3"</option>
              <option value="4">4"</option>
              <option value="5">5"</option>
              <option value="6">6"</option>
              <option value="7">7"</option>
              <option value="8">8"</option>
              <option value="9">9"</option>
              <option value="10">10"</option>
              <option value="11">11"</option>
            </select>
          </div>
        </div>
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={this.state.disabled}
            className={`btn` + (this.state.disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default Height
