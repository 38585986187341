import React, { useState } from 'react'
import { func, object } from 'prop-types'
import { getValidation } from 'utils/validation'
import get from 'lodash/get'
import flatMap from 'lodash/flatMap'

import InfoBox from '../../info-box'

const rules = {
  weight: 'required|numeric|max:900|min:40',
}

const Weight = ({ submitData, handleNext, captive }) => {
  const initValue = get(captive, 'data.weight', '')
  const [value, setValue] = useState(initValue)
  const [disabled, setDisabled] = useState(!initValue.length)
  const [errors, setErrors] = useState(null)
  const handleSubmit = (e) => {
    e.preventDefault()
    const validation = getValidation({ data: { weight: value }, rules })
    if (!validation.passes()) {
      setErrors(validation.errors)
    } else {
      setErrors(null)
      submitData({ weight: value })
      handleNext('weight')
    }
  }

  const handleChange = (event) => {
    const isDisabled = !(event.target.value !== '' && event.target.value.length > 1)
    setValue(event.target.value)
    setDisabled(isDisabled)
  }

  return (
    <div>
      <strong className="h4">What is your weight?</strong>
      <form
        action="#"
        onSubmit={handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col full-width">
            <label htmlFor="weight" className="hidden">
              Weight
            </label>
            <input
              maxLength="4"
              type="text"
              id="weight"
              data-required="true"
              value={value}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={disabled}
            className={`btn` + (disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
        {errors && (
          <div className="mt3">
            {flatMap(
              Object.keys(errors.errors).map((x) => errors.errors[x]),
              (text, i) => (
                <InfoBox key={i} text={text} error />
              ),
            )}
          </div>
        )}
      </form>
    </div>
  )
}

Weight.propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

export default Weight
